import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { getPrincipalMessage } from '../Service/Api';
import Slider from 'react-slick';  // Import Slider from react-slick
import LazyLoad from 'react-lazyload';

const Testimonials = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
      const fetchData = async () => {
          try {
              const principalData = await getPrincipalMessage();
              setData(principalData);
          } catch (error) {
              console.log("Data Error", error);
          } finally {
              setLoading(false);
          }
      };
      fetchData();
  }, []);

  const emptyData = [
    {
      category: "Principal",
      title: 'Principal Message',
      name: 'Sr. Lisbeth SABS',
      designation: <>Principal<br/>St. Joseph Convent School</>,
      message: <>"Intelligence plus character : that is the goal of true education".(Martin Luther King Jr.)<br/>Education is not just the process of imparting knowledge alone but a lifelong learning process which enhances our understanding of moral and ethical values to guide one's life and make their future more bright and successful. We at St. Joseph play a pivotal role of providing education to the children by nurturing their in our pupils the valued spiritual growth in all the aspects of their life. We encourage all the students to contribute the give their very best in their attempt to realize their dreams. Students at St. Joseph are motivated to develop leadership skills and a thorough understanding of interdependence while holding independent views of their own. We strive for excellence in everything we do.<br />Warm Regards</>,
      image: '/Images/principal.jpg',
      link: '/'
    },
    {
      category: "Director",
      title: 'Director Message',
      name: 'Rev. Fr. Jose Sebastian T',
      designation: <>Director<br/>St. Joseph Convent School</>,
      message: <>"Education is the most powerful weapon which you can use to change the world." (Nelson Mandela)<br/>At St. Joseph, we focus on holistic development, nurturing the minds and spirits of our students to equip them for challenges beyond school. Our commitment is to guide each child to their highest potential.<br />Warm Regards</>,
      image: '/Images/director.jpg',
      link: '/'
    }
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true
  };

  return (
    <div className="testimonial-slider">
      {/* Check if data has content and render accordingly */}
      <Slider {...sliderSettings}>
        {/* Iterate over the fetched data dynamically */}
        {data.length > 0 ? (
          data.map((item, index) => (
            <div className="item" key={index}>
              <div className="message-container">
                <div className="messagebox"> 
                  <div className="messagedesc">
                    <div className="maintitle">
                      <h3>{item.category}'s Message</h3>
                    </div>
                    <div className="msginfo">
                      <div dangerouslySetInnerHTML={{ __html: item.message }} />
                    </div>
                  </div>
                  <div className="messageboximg">
                    <p>{item.name} <span>{item.category}</span></p>
                  </div>
                </div>

                <div className="messageimg"> 
                  <div className="testimonialimg">
                  <LazyLoad>  <img
                      src={`https://webapi.entab.info/api/image/${item.attachments || 'default.jpg'}`}
                      alt={`${item.category} - St. Joseph's Convent School`}
                      className="img-fluid"
                    /></LazyLoad>
                  </div>
                  <div className="mainbtn button">
                    <Link to={`${item.category}Message`}>READ MORE</Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          // Fallback to empty data
          emptyData.map((data, index) => (
            <div className="item" key={index}>
              <div className="message-container">
                <div className="messagebox"> 
                  <div className="messagedesc">
                    <div className="maintitle">
                      <h3>{data.category}'s Message</h3>
                    </div>
                    <div className="msginfo">
                      <p>{data.message}</p>
                    </div>
                  </div>
                  <div className="messageboximg">
                    <p>{data.name} <span>{data.designation}</span></p>
                  </div>
                </div>

                <div className="messageimg"> 
                  <div className="testimonialimg">
            <LazyLoad>        <img
                      src={data.image}
                      alt={`${data.category} - St. Joseph's Convent School`}
                      className="img-fluid"
                    /></LazyLoad>
                  </div>
                  <div className="mainbtn button">
                    <Link to={data.link}>READ MORE</Link>
                  </div> 
                </div>
              </div>
            </div>
          ))
        )}
      </Slider>
    </div>
  );
};

export default Testimonials;
