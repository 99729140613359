import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
const Curricular = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link>{" "}</li>
          <li>Academics</li>
          <li>Co-curricular Activities</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Co-curricular Activities</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>With a view to develop the quality of leadership and to promote healthy competitions among the students, all the activities are organized on the basis of dividing the students into various Houses. The whole school is divided into four houses. The House activities are under the supervision of the House In- charge teachers and House Captains. Each house performs various duties for a month in rotation. Facilities are provided for all kinds of cultural activities.</p>
              <p>Physical education for students is provided through healthy competitions in sports and games. Qualified and capable instructors are readily made available for training the students. Individual performance of the students is given full attention, along with instilling in them a spirit of co-operation and sportsmanship.</p>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default Curricular;
