import React from 'react'
import Popup from '../Component/Popup' 
import Campuscare from '../Component/Campuscare'
import HomeSlider from '../Component/HomeSlider'
import TopperXII from '../Component/TopperXII'
import TopperX from '../Component/TopperX' 
import Events from '../Component/Events'
import News from '../Component/News'  
import Gallery from '../Component/Gallery'
import Message from '../Component/Message'  
import { Link } from 'react-router-dom'
import ClassTabs from '../Component/ClassTabs' 
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Birthday from '../Component/Birthday'
import LazyLoad from 'react-lazyload'
const Home = () => {
  return (
    <>
    <Header />
        <Campuscare /> 
      <Popup /> 
      <HomeSlider />
      <div className="aboutmidsec padded-sec">
        <div className='button'>
          <Link to="/" target="_blank">
            <p>CBSE Disclosure</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="https://www.sjpcampuscare.in" target='_blank'>
            <p>online payment</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="https://www.sjpcampuscare.in" target="_blank">
            <p>alumnae registration</p></Link>
        </div>
        <div className='phone'>
          <a href="tel:9257217537">
            <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/phone.gif" alt="St. Joseph's Convent School, Phagwara" /></LazyLoad>
          </a>
        </div>
        <div className='button'>
          <Link to="/">
            <p>bus route</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="/Pphelp">
            <p> Portal login</p>
          </Link>
        </div>
        <div className='button'>
          <Link to="/ErpSoftware" target="_blank">
            <p>ERP Login</p>
          </Link>
        </div>
      </div>
      <div className="aboutsec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className="col-lg-6 about-bg">
              <div class="about-news">
                <div className='title'>
                  <p>about us</p>
                  <h2>St. Joseph's Convent School</h2>
                </div>
                <p>St. Joseph's Convent School, Phagwara is an English Medium School founded under the aegis of the Diocese of Jalandhar and was established in the year 1978 and started functioning with 238 students in L.K.G., U.K.G. and 1st std on 13th of March of the same year and was officially inaugurated on the 1st of April 1979 by the Chief Minister of Punjab. Undoubtedly this is the Premier Institution of this area and it has carved a prominent niche for itself in the history of education in Punjab. This is a high school and also has primary and middle school sections from Std. 1st to 10th. It is run by the Sisters of the Adoration of the Blessed Sacrament (SABS) under the management of the Diocese of Jalandhar. It Prepares students for the Indian Certificate of Secondary Education (ICSE).</p>
                <Link to="/About"><button className='button'>Read More</button></Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img">
                <div className='abt_abs abs-img1'><LazyLoad><img src='https://webapi.entab.info/api/image/SJSP/public/Images/about1.png' alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></div>
                <div className='abt_abs abs-img2'><LazyLoad><img src='https://webapi.entab.info/api/image/SJSP/public/Images/about2.png' alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></div>
                <div className='center-img'><LazyLoad><img src='https://webapi.entab.info/api/image/SJSP/public/Images/aboutimg.png' className='abs-img3' alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></div>
                <div className='abt_abs abs-img4'><LazyLoad><img src='https://webapi.entab.info/api/image/SJSP/public/Images/about3.png' alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></div>
                <div className='abt_abs abs-img5'><LazyLoad><img src='https://webapi.entab.info/api/image/SJSP/public/Images/about4.png' alt="St. Joseph's Convent School, Phagwara" /></LazyLoad></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='classSection'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <ClassTabs />

            </div>

          </div>
        </div>
      </div>
      {/* <div className='calender'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='title'>
                <h2>St. Joseph's Convent School, Phagwara, Jamshedpur </h2>
              </div>
              <Calender />

            </div>

          </div>
        </div>
      </div> */}
      <div className="event">
        <div className="container fulwidth">
          <div className="row">
            <div className="col-lg-12 section-title">
              <div className='title'> 
                <h2>From The Desk Of</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <Message />
            </div>
          </div>
        </div>
      </div>
      <div className='events padded-sec'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-xl-6 col-md-12'>
            <div className='title'> 
                <h2>Events section</h2>
              </div>
              <Events />
            </div>
            <div className='col-xl-6 col-md-12'>
            <div className='title'> 
                <h2>Latest News/Announcement</h2>
              </div>
              <News />
            </div>
          </div>
        </div>
      </div>
      <div className="topper_bg padded-sec" id="Topper">
        <div className="container-fluid section-title">
          <div className='row'>
            <div className='col-lg-12 col-xl-8'>
            <div className="row">
            <div className='col-md-12'>
              <div className='topper-title'>
                <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/topper.png" className='img-fluid m-auto d-block' alt="St. Joseph's Convent School, Phagwara" /></LazyLoad>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='topper-block'>
              <div className='title'> 
                <h2><span>X</span> Toppers</h2>

                <div className='arrrow-btn'>
                  {/* <Link to="/">
                    <i class="bi bi-chevron-right"></i></Link>*/}
                </div> 
              </div>
              <TopperX />
              </div>
              <button className='button'><Link to="/Toppers">Read More</Link></button>
            </div>    
                
            <div className="col-lg-6">
              
              <div className='topper-block'>
              <div className='title'> 
                <h2><span>XII</span>  Toppers</h2>
                <div className='arrrow-btn'>
                  {/* <Link to="/">
                    <i class="bi bi-chevron-right"></i>
                  </Link> */}
                </div>
              </div>
              <TopperXII />
              </div>
              <button className='button'><Link to="/ToppersXII">Read More</Link></button>
            </div>
          </div>
            </div>
            <div className='col-lg-12 col-xl-4'> 
            <div className='birthday-title'>
                <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/birthday.png" className='img-fluid m-auto d-block' alt="St. Joseph's Convent School, Phagwara" /></LazyLoad>
              </div>
          
            <div className='topper-block'>
              <div className='title'>  
                <div className='arrrow-btn'>
                  {/* <Link to="/">
                    <i class="bi bi-chevron-right"></i>
                  </Link> */}
                </div>
              </div>
              <Birthday />
              </div>
            </div>
          </div>
         
        </div>
      </div> 
    
      <div className="homegallery padded-sec">
        <div className="container-fluid fulwidth">
          <div className="row">
            <div className='col-md-12 section-title'>
              <div className='title'> 
                <h2>activities in images</h2>
              </div>
            </div>
            <div className="col-lg-12">

              <Gallery />
              <button className='button'><Link to="/Gallery">Read More</Link></button>
            </div>
          </div>
        </div>
      </div>
       
      <Footer/>
    </>
  )
}

export default Home