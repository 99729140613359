import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const Leadership = () => {

    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>Academics</li>
                    <li>Discipline & Leadership</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Discipline & Leadership</h1>
                        </div>
                    </div>
                    <div className="row uniform_blk">
                        <div className='col-md-12'>
                        <p>With a view to develop the quality of leadership and promote a healthy competition among the students, all the activities are organized on House Basis. The whole school is divided into four houses namely, JOSEPHINE, MARIAN, THERESIAN and THOMSIAN for healthy inter-house competitions. The house activities are under the supervision of the House in charge teachers and house captains. Each house has a colour of its own. Each Every house goes on is assigned duty for a month in rotation. It provides enough opportunity to each student to make progress by understanding the importance of self-discipline and confidence.</p>
                   
                        </div>
                        <div class="col-xs-6 col-sm-4 col-md-4 col-lg-4">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/ds1.jpg" class="img-responsive img-fluid border-box" alt="St. Joseph's Convent School Phagwara"/></LazyLoad></div>
<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/ds2.png" class="img-responsive img-fluid border-box" alt="St. Joseph's Convent School Phagwara"/></LazyLoad></div>
<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/ds3.png" class="img-responsive img-fluid border-box" alt="St. Joseph's Convent School Phagwara"/></LazyLoad></div>
<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/ds4.png" class="img-responsive img-fluid border-box" alt="St. Joseph's Convent School Phagwara"/></LazyLoad></div>
<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/ds5.png" class="img-responsive img-fluid border-box" alt="St. Joseph's Convent School Phagwara"/></LazyLoad></div>
<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/ds6.png" class="img-responsive img-fluid border-box" alt="St. Joseph's Convent School Phagwara"/></LazyLoad></div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Leadership

