import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LazyLoad from "react-lazyload";
const HeavenlyPatrons = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>About</li>

          <li>Our Heavenly Patron</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Our Heavenly Patron</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
                <LazyLoad><img class="img-responsive img-fluid border-box left-img" src="https://webapi.entab.info/api/image/SJSP/public/Images/Heavenly_Patron.png" alt="St. Joseph's Convent School Phagwara" /></LazyLoad> 
                <p>In God's Providence the noble soul, St. Joseph, who was chosen to be the earthly guardian of Holy Mary and the Foster-Father of Jesus Christ, was born in the family of David the king of the Jews. All that is known of this just man is contained in the Holy Bible. He was a Carpenter by profession and an example for all those who must earn their daily bread by the toil of their hands sweat. Pope Pius XII gave a concrete expression of the special relationship between St. Joseph and the working class, by proclaiming the feast of St. Joseph the Worker, and fixing the feast on Labor day, the 1st of May. The Catholic Church teaches that St. Joseph has been designated by God as "The master of his goods and of his household". His role as the faithful guardian of Holy Family inspired the Church to entrust the unique patronage of the women Religious to Saint Joseph, who is the patron saint of the Universal Church. As the Foster Father of Jesus Christ, he continues to play the role of a format or model guard for the student community and of a guide and master for the teaching community.</p>
                <p>Reflecting on the gratitude of St. Joseph, the Dominican friar St. Thomas of Aquinas, noted, "Some Saints are privileged to extend to us their patronage with particular efficacy in certain needs, but not in others; but our holy Patron St. Joseph has the power to assist us in all cases, in every necessity and in every undertaking"."And when you stand praying, if you hold anything against anyone, forgive him, so that your Father in heaven may forgive you your sins. (Mark 11:25)" Our vision of education is based on the conviction that education has a unique and distinctive role to play in a man's integral growth as well as in the society's and the development and reconstruction of the Society as a whole.</p>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default HeavenlyPatrons;
