import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LazyLoad from "react-lazyload";
const VisionAim = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>About</li>

          <li>Our vision, Aim and objectives</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Our vision, Aim and objectives</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
                <LazyLoad><img class="img-responsive img-fluid border-box left-img" src="https://webapi.entab.info/api/image/SJSP/public/Images/vision_aims.jpg" alt="St. Joseph's Convent School Phagwara" /></LazyLoad>
                <p>This is co-education, open to students of all religions, and holds the Motto,SCIREAMAREET SERVIRE (in Latin) meaning ,To LEARN,TO LOVE,AND TO SERVE. Although this is a Christian Minority School, owned and administered by the Catholic Church, every student is free and encouraged to practice his/her religion. Our aim is that the virtues of knowledge and love should culminate in selfless service of our brothers and sisters according to the Indian tradition of KARMA YOGA.</p>
                <p>Every effort is being made to impart a healthy and all-round education, in keeping with our Nation’s aspirations of the future.</p>
                <p>The education methodology followed by this school is student friendly and it encourages analytical, creative and independent thinking. This is done by assisting the student in developing value based and holistic perspectives, all-round developments, positive thinking, healthy attitude, sound domain of knowledge and practical application of it. This school has evolved a comprehensive, student-oriented learning approach comprising different stages, intended to impart essential values for the integral development of every student.</p>
                <p>The staff and the management of this school, like other convent schools run by the Diocese of Jalandhar, strive with dedication and commitment, to provide value based and quality education to equip the students to compete and excel in every situation.</p>
                <p>Teachers of this school, as guides and facilitators, are expected to bring out the best in every student, keeping in mind the myriads of talents and abilities of each one.</p>
                <p>Being aware of the rapid growth of education and the globalization of life and work, this school keeps itself abreast with different methodologies in imparting knowledge.</p>
                <p>Every student is encouraged and assisted to develop analytical and critical thinking along with innovative skills for a meaningful and successful future. In addition to book reading , to help learn by doing, project works and personal initiatives form part of our education methodology.</p>
                <p>Special emphasis is given to help every student to grow up as fully human and filly alive, by imparting human, moral and religious values, like truthfulness, honesty, sincerity, self -discipline, courtesy and politeness, social harmony, religious tolerance etc. Every student is being taught to be a master and architect of one’s own future.</p>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default VisionAim;
