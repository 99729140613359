import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
const SportsAndGames = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link>{" "}</li>
          <li>Academics</li>
          <li>Sports and Games</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Sports and Games</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>Physical Education has an important role to play in formulating a healthy body and mind. Accordingly sports and games constitute an integral part of the extra curricular activities of the school is provided with healthy sports games. Competent and experienced Capable instructors are readily made available. Individual performance of the student is given all attention and every efforts are made to foster along with the instilling of spirit of co-operation and sportsmanship among the students. There is arrangement for team games; cricket, Foot-ball, Basket Ball, Hockey, Badminton, Valley-Ball etc. Students learn Judo, karate and Yoga too under the expert guidance. The school organizes sports week every year to encourage competition and team spirit among students and they enjoy the recognize a display of strength, stamina, vigor and sportsmanship among themselves. Numerous Athletic events like Long-Jump, Shot put, Relay-race etc. take place during the Sports-Week and winners are awarded with medals and prizes in the presence of dignitaries.</p>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default SportsAndGames;
