import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api"; 
import { Link } from 'react-router-dom'
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LazyLoad from "react-lazyload";
const SubGallery = () => {
  useEffect(() => {
    document.title = 'Sub Gallery - St Joseph School, Phagwara';  
}, []);  
    const navigate = useNavigate();
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");
    const [data, setData] = useState("");
    const [index, setIndex] = React.useState(-1);
  
    console.log(id);
  
    useEffect(() => {
      const getData = async () => {
        try {
          let subgalleryData = await getSubGallery(id);
          console.log(subgalleryData);
          setData(subgalleryData);
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, []);
  
    console.log(data);
  
    const slides = data?.attachments?.map(img => ({
      src: `https://webapi.entab.info/api/image/${img}`
    }));
  return (
     <>
       <Header/>
     <div className="innerslide">
     <ul className="breadcrumb">
         <li><Link to="/"> Home</Link> </li> 
        
          <li>  Gallery </li>
          <li> Sub Gallery </li>
     </ul>
 </div> 
 <div className="innersec">
     <div className="container">
         <div className="row">
             <div className="col-lg-12">
                 <h1> Sub Gallery </h1>
             </div>
         </div>
                <div className="row tabs-dynamic">
                <div className="col-md-6 col-xl-3">
                    <div className="count-val">
                        <p><Link to="/Gallery"><i className="bi bi-arrow-left"></i> Go Back</Link>  </p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="month-selection">
                        <p><span>Event Date:</span> {" "}
                        {new Date(data.date).toLocaleDateString("en-GB")}</p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="month-selection">
                        <p><span>Last Update:</span> {new Date(data.date).toLocaleDateString("en-GB")}</p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="searchBox">
                        <p><span>No. Of Photos:</span> {data?.attachments?.length} </p>
                    </div>
                </div>
               </div>
               <div className="row">
                <div className="col-md-12">
                    <h4 className="gallery-title">{data.title}</h4>
                    <p>{data.description}</p>
                </div>
                {data !== "" &&
                data?.attachments.map((img,index) => (
             <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
                <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)}><img src={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)} className=" img-fluid border-box" alt="St Joseph Convent School, Phagwara" /></a> 
             </div> 
             ))} 
             
             {/*  <div className=" col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount"> 
              <a data-magnify="gallery" data-src="" data-group="a" href="images/gallery4.jpg"><img src="https://webapi.entab.info/api/image/HTSJ/public/Images/sgallery4.jpg" className=" img-fluid" alt="St Joseph School, Phagwara" /></a> 
           </div>  */}
         </div> 
        </div>
        </div>
        <Footer/>
    
    </>
  )
}

export default SubGallery
