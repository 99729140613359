import React from 'react';
import { Link } from 'react-router-dom'  
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const About = () => { 
    
  return (
     <>
     <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                
                 <li> About</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>About Us</h1>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-12'>
                    <p>St. Joseph's Convent School, Phagwara is an English Medium School founded under the aegis of the Diocese of Jalandhar and was established in the <span>year 1978 </span>and started functioning with <span>238 students in L.K.G., U.K.G. and 1st std</span> on 13th of March of the same year and was officially inaugurated on the 1st of April 1979 by the Chief Minister of Punjab. Undoubtedly this is the Premier Institution of this area and it has carved a prominent niche for itself in the history of education in Punjab. This is a high school and also has primary and middle school sections from Std. 1st to 10th. It is run by the Sisters of the Adoration of the Blessed Sacrament (SABS) under the management of the Diocese of Jalandhar. It Prepares students for the Indian Certificate of Secondary Education (ICSE).</p>
                   
                    </div>
                </div>
            </div>
        </div>
        <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/about.jpg" className='img-fluid'></img></LazyLoad>
        <Footer />
     </>
  )
}

export default About

