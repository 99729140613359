import React from 'react';
import { Link } from 'react-router-dom'  
import Header from '../Component/Header';
import Footer from '../Component/Footer';
const Uniform = () => { 
    
  return (
     <>
     <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>Academics</li>                
                 <li>School Uniform</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>School Uniform</h1>
                    </div>
                </div>
                <div className="row uniform_blk">
                    <div className='col-md-12'>
                        <h3 className='sub_title'>SUMMER UNIFORM</h3>
                    </div>
                    <div className='col-md-6'>
                        <ul className="list_view">
                        <li><strong>Boys I-V </strong>: Checked Grey Shorts, plain grey shirt, school socks, belt & black shoes with laces.</li>
<li><strong>BoysVI-X </strong>: Plain grey pant, checked grey & white shirt, school socks, belt tie & black shoes, grey patka.</li>

                        </ul> 
                    </div>
                    <div className='col-md-6'> 
                        <ul className="list_view">
                        <li><strong>Girls I-IV</strong> : Checked grey skirt with plain grey shirt, school socks, black shoes & belt, grey ribbon.</li>
<li><strong>GirlsV-X</strong>: Checked grey divided skirt(compulsory) with plain grey shirt, school socks, black shoes & belt, grey ribbon.</li>

                        </ul>
                    </div>
                    <div className='col-md-12'>
                        <h3 className='sub_title'>WINTER UNIFORM</h3>
                    </div>
                    <div className='col-md-6'>
                        <ul className="list_view">
                        <li><strong>Boys I-X</strong>: Dark grey pant, full sleeved cream shirt with V-necked blue pullover, maroon blazer, grey socks, black shoes, belt and tie, maroon patka.</li>

                        </ul> 
                    </div>
                    <div className='col-md-6'> 
                        <ul className="list_view">
                        <li><strong>Girls I – IV</strong>: Dark grey skirt, full sleeved cream shirt with V-necked blue pullover, maroon blazer, grey socks, grey stockings, black shoes, belt and tie, maroon ribbon.</li>
                        <li><strong>Girls V-X </strong> : Dark grey divided skirt, full sleeved cream shirt with V-necked blue pullover, maroon blazer, grey socks, grey stockings, black shoes, belt and tie, maroon ribbon.</li>

                        </ul>
                    </div>
                    <div class="col-lg-12">
                        <h3 className='sub_title'>P.T. Uniforms</h3>
                        <p>On every Saturday White pant/divided skirt(for V-X only) and house T-shirt with white shoes and white patka.</p>
                        <p>On Wednesday and Saturday STD Nur- UKG will come in Housewise activity dress.</p>
                    </div>
                </div>
            </div>
        </div>
      
        <Footer />
     </>
  )
}

export default Uniform

