import React from 'react';
import { Link } from 'react-router-dom'  
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const Prayer = () => { 
    
  return (
     <>
     <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>About</li>  
                 <li> Prayer</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Prayer</h1>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-12'>
                    <p><p>Everyday the School begins it's day with the Morning Assembly. There is a solemnity and grace in the assembly which begins with a prayer by all students and teachers together holding the motto <span>"the family that prays together stays together"</span>. After the break all the students call out Our Father in Heaven by reciting the prayer <span>'Our Father'</span>. And there is thanksgiving prayer too at the end of the day.</p></p>
                   
                    </div>
                    
                    <div className='col-md-12'>
                        <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/prayer.jpg" className='border-box img-fluid'></img></LazyLoad>
                    </div>
                </div>
            </div>
        </div> 
        <Footer />
     </>
  )
}

export default Prayer

