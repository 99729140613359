import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const ComputerLab = () => {
    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>Infrastructure</li>
                    <li>Computer Lab</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Computer Lab</h1>
                        </div>
                    </div>
                    <div className="row uniform_blk">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/computer-lab.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                         
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ComputerLab

