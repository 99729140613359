import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
const AffiliationCourse = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>About</li>

          <li>Affiliation and Course of study</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Affiliation and Course of study</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
                <p>The school is permanently affiliated to the Council for the Indian School Certificate Examinations (CISCE), New Delhi, Vide No.PU019. The University Of Cambridge Local Examinations Syndicate with the assistance of Inter State for Anglo – Indian Education established the Council for the Indian School Certificate Examinations in 1958. In 1973 the Govt. of India recognized the Council as body to conduct Public Examinations in India through on Act of the bodies. Indian Certificates of Secondary Education(ICSE) is recognized by all the Education boards in the country and by many overseas too.</p>
                <p>This is a Co-Educational school which prepares students for the Indian Certificate of Secondary Education(ICSE) New Delhi, from the Nursery classes up to 10th STD and every student is required to take the following subjects as course of study up to class 10th.</p>
                <ul className="list_view">
                    <li>English (Compulsory to get pass-mark for obtaining the pass certificate.)</li>
                    <li>Punjabi (compulsory till the 10th standard.)</li>
                    <li>Hindi (being the National Language of India, compulsory up to 8th standard for all students). Those students who wish to get a pass certificate in Hindi subject should continue to take Hindi as an optional language, also in the 9th and 10th std. the school will make the necessary arrangements to engage a teacher, monitored by the Principal, to teach Hindi on request and on payment of an extra fee.</li>
                    <li>Mathematics</li>
                    <li>Science (Physics, Chemistry, Biology)</li>
                    <li>History, Civics, Geography</li>
                    <li>Physical Education/Computer Application</li>
                    <li>Socially Useful Productive work & Community service</li>
                </ul>
                <p>ICSE pass certificate will be awarded to candidate who attain the pass mark for English plus Four subject. However, it is compulsory to secure a pass grade in S.U.P.W.</p>
                <h3 className="sub_title">Medium of Instruction</h3>
                <p>The medium of instruction is English and the method of teaching and the curriculum are in accordance with the scheme laid down by the Council for the Primary, Secondary and the Higher Secondary stages approved by the council. Special importance is given to the mastery of English, including spoken English, for which a high standard is maintained. Hindi as the national language and Punjabi as the regional language are taught from Pre-Primary level with due priority.</p>
                <h3 className="sub_title">Computer Education</h3>
                <p>In today’s world, Information and Technology get the pick of the bunch. Man is out-fashioned without the knowledge of computer. Also, now the time has come when every person seeking employment either in private or Govt. sectors inevitably finds himself/herself, confronted with the need to be competent in computer technology to meet the challenges of the time. Students must be exposed to the computer environment at an early age, when they are at the primary learning stage and when their ability to grasp is high. Exposure to computers at this stage of life will certainly help them in future, no doubt for a better career in the computer field, engineering etc.</p>
                <p>In order to equip the students to face the challenges of the modern technological era, computers are becoming a necessity rather than a luxury. Keeping this in view to give the best of the modern technology to the children, Computer science is being introduced in the school curriculum and it is being taught as an additional compulsory subject. This is also one of the optional subjects for the I.C.S.E./CBSE Board Exam.</p>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default AffiliationCourse;
