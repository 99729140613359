import React, { useState } from "react";
import {Link } from 'react-router-dom'
import LazyLoad from "react-lazyload";

const ClassTabs = () => {
    // State to keep track of the active tab
    const [activeTab, setActiveTab] = useState(0);

    // Array of tab data including images and content
    const tabData = [
        {
            title: <>Admission  <br />Details</>,
            image: "https://webapi.entab.info/api/image/SJSP/public/Images/admissiondtl.jpg", // Use the imported image
              content: "The Academic year begins in March and ends in February.Admission to the various classes shall be opened to all irrespective of class, creed or race and as per the availability of seats. However the school Authorities and the Principal reserve the right to refuse admission to a student who in their opinion is not or will not be able to cope up with the studies and syllabus as prescribed by the school or if the student is found to be of poor health or delinquent character which would affect him/her detrimentally during the course of studies, or may affect others in the schoolin their studies and character formation. In all such cases the decision of the school authorities and the Principal is binding and final. Registration of a child does not guarantee admission, it only means that the child is entitled to appear for an entrance test and interview, after which the school authorities and the Principal will decide whether admission is to be given or not.",
            readMore: '/AdmissionRequirement'
        },
        {
            title: <>Alumni  <br />Support</>,
            image: "https://webapi.entab.info/api/image/SJSP/public/Images/alumni.jpg", // Use the imported image
              content: "The Academic year begins in March and ends in February.Admission to the various classes shall be opened to all irrespective of class, creed or race and as per the availability of seats. However the school Authorities and the Principal reserve the right to refuse admission to a student who in their opinion is not or will not be able to cope up with the studies and syllabus as prescribed by the school or if the student is found to be of poor health or delinquent character which would affect him/her detrimentally during the course of studies, or may affect others in the schoolin their studies and character formation. In all such cases the decision of the school authorities and the Principal is binding and final. Registration of a child does not guarantee admission, it only means that the child is entitled to appear for an entrance test and interview, after which the school authorities and the Principal will decide whether admission is to be given or not.",
            readMore: '/'
        },
        {
            title: <>Faculty <br /> Details</>,
            image: "https://webapi.entab.info/api/image/SJSP/public/Images/faculty.jpg", // Use the imported image
              content: "Comming Soon...",
            readMore: '/'
        },
        {
            title: <>Scheme of  <br />Study</>,
            image: "https://webapi.entab.info/api/image/SJSP/public/Images/scheme.jpg", // Use the imported image
              content: "Comming Soon...",
            readMore: '/'
        },
        {
            title: <>Students  <br />Birthday  </>,
            image: "https://webapi.entab.info/api/image/SJSP/public/Images/studentBirthday.jpg", // Use the imported image
              content: "The Academic year begins in March and ends in February.Admission to the various classes shall be opened to all irrespective of class, creed or race and as per the availability of seats. However the school Authorities and the Principal reserve the right to refuse admission to a student who in their opinion is not or will not be able to cope up with the studies and syllabus as prescribed by the school or if the student is found to be of poor health or delinquent character which would affect him/her detrimentally during the course of studies, or may affect others in the schoolin their studies and character formation. In all such cases the decision of the school authorities and the Principal is binding and final. Registration of a child does not guarantee admission, it only means that the child is entitled to appear for an entrance test and interview, after which the school authorities and the Principal will decide whether admission is to be given or not.",
            readMore: '/'
        },
        {
            title: <>School <br />Toppers</>,
            image: "https://webapi.entab.info/api/image/SJSP/public/Images/topper.jpg", // Use the imported image
              content: "The Academic year begins in March and ends in February.Admission to the various classes shall be opened to all irrespective of class, creed or race and as per the availability of seats. However the school Authorities and the Principal reserve the right to refuse admission to a student who in their opinion is not or will not be able to cope up with the studies and syllabus as prescribed by the school or if the student is found to be of poor health or delinquent character which would affect him/her detrimentally during the course of studies, or may affect others in the schoolin their studies and character formation. In all such cases the decision of the school authorities and the Principal is binding and final. Registration of a child does not guarantee admission, it only means that the child is entitled to appear for an entrance test and interview, after which the school authorities and the Principal will decide whether admission is to be given or not.",
            readMore: '/'
        }
    ];

    const handleTabClick = (index) => {
        // If the "Alumni" tab (index 1) is clicked, redirect to the external link
        if (index === 1) {
            window.open("https://www.sjpcampuscare.in/", "_blank");// Redirect to the Alumni page
        } else if (index === 4) {
            // If the "Topper" tab is clicked, scroll to the Birthdays section
            const birthdaysSection = document.getElementById("Birthdays");
            if (birthdaysSection) {
                birthdaysSection.scrollIntoView({ behavior: "smooth" });
            }
        } 
        else if (index === 5) {
            // If the "Topper" tab is clicked, scroll to the Birthdays section
            const birthdaysSection = document.getElementById("Topper");
            if (birthdaysSection) {
                birthdaysSection.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            setActiveTab(index); // Default tab switching
        }
    };

    return (
        <div className="class-tabs">
            <div className="tabs">
                {tabData.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab ${activeTab === index ? "active" : ""}`}
                        onClick={() => handleTabClick(index)} // Call handleTabClick
                    >
                        <div className="tab-title">{tab.title}</div>
                        <div className="tabs-img">
                        <LazyLoad><img src={tab.image} alt={tab.title} className="tab-image" /></LazyLoad>
                        </div>
                    </div>
                ))}
            </div>
            <div className="tabs-content">
                <div className="content">
                    <p>{tabData[activeTab].content}</p>
                   <Link to={tabData[activeTab].readMore}> <button className="button" href={tabData[activeTab].readMore}>Read More</button></Link>
                </div>
            </div>
        </div>
    );
};

export default ClassTabs;
