import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const MusicDance = () => {
    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>Infrastructure</li>
                    <li>Music & Dance</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Music & Dance</h1>
                        </div>
                    </div>
                    <div className="row uniform_blk">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/music2.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/music1.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/infra_dance1.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/infra_dance2.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MusicDance

