import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
const AdmissionRequirement = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>Admission</li>

          <li>Admission Requirements</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Admission Requirements</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
               <ul className="list_view">
                <li>The Academic year begins in March and ends in February.</li>
                <li>Admission to the various classes shall be opened to all irrespective of class, creed or race and as per the availability of seats. However the school Authorities and the Principal reserve the right to refuse admission to a student who in their opinion is not or will not be able to cope up with the studies and syllabus as prescribed by the school or if the student is found to be of poor health or delinquent character which would affect him/her detrimentally during the course of studies, or may affect others in the schoolin their studies and character formation. In all such cases the decision of the school authorities and the Principal is binding and final. Registration of a child does not guarantee admission, it only means that the child is entitled to appear for an entrance test and interview, after which the school authorities and the Principal will decide whether admission is to be given or not.</li>
                <li>Students are usually admitted only at the beginning of the school year. Admission to Nursery is to be taken at the age of three and a half years.</li>
                <li>Those who seek admission to classes other than to Nursery Section will have to bring the school leaving certificate from the school previously attended. At the time of the entrance test it would suffice to have a bona fide certificate from the previous school. The Principal is at liberty to assess the knowledge of the student so as to determine the standard most suited to a child after considering the result of the entrance test. Birth certificate from the Municipality/Hospital, duly attested should be produced at the time of admission. If it is found that a false birth certificate has been produced to obtain admission, the candidate will be barred from studying in this institution. Date of birth as shown in the birth certificate once registered cannot be altered on any account.</li>
                <li>Parents/Guardians are asked to fill up the admission form with the utmost possible accuracy. No subsequent changes will be permitted thereafter for any reason whatsoever.</li>
                <li>No student will be admitted, except for the nursery class, without a prior test and interview. The admission procedure will be completed only after submitting the necessary documents and depositing the fees. Failing to do so within the stipulated date and time for availing the admissionwill result in the cancellation of admission within 3 days, and the seat will be allotted to the next child in the waiting list. Fee once paid will not be refundable. Admission to any class will be solely under the discretion of the Principal.</li>
                <li>Due to the limitation of available seats, the school cannot guarantee seats for the siblings of the students already studying in the school.</li>
                <li>When there are too many applicants the management may choose to provide admission by a system of drawing lots. There is no guarantee that all those who register the names will get admission; but however the registration fee will not be refunded to those who could not get admission.</li>
               </ul>
               <h3 className="sub_title">Administrative Matters Registration</h3>
               <ul className="list_view">
                <li>Those who wish to admit their children in this school must buy the prospectus of the school and study in detail the terms and conditions of the admission process and the subsequent code of conduct for the parents and the students to be followed if admission is granted.</li>
                <li>The prospectus is available at the reception counter of the school for the parents to buy.</li>
                <li>All prospective candidates for any class must get their names registered at the school office. The dates of registration shall be intimated through the principal’s circular. No registration shall be made prior to the dates specified.</li>
                <li>Initially Registration Forms will be disbursed for admission to Kindergarten (Nursery class) along with the prospectus.</li>
                <li>Anyone is free to collect the Registration Form from the school office along with the Prospectus within the announced period of time by paying the charges for the Form and the prospectus. Registration Charges, the cost of the Form and prospectus are not refundable.</li>
                <li>After having thoroughly studied the terms and conditions inscribed in the prospectus, those who wish to seek admission of theirchild to this school may fill up the Registration Form and submit it to the office within the specified period of time along with the required preliminary documents and Registration fee. The Registration Fee is not refundable.</li>
                <li>The school will examine the Registration Forms, and the eligible applicants as per the terms and conditions mentioned in the registration form will be invited to be present for the drawing of lots, if there are too many applicants on the fray.</li>
                <li>Availability of vacant seats to higher classes would be very scarce, as it is very rare that any student leaves the school once taken the admission. Registration date for filling the vacant seats in higher classes may be informed to those who enquire from the school office.</li>
               </ul>
               <h3 className="sub_title">Admission</h3>
               <p>Admission is welcomed mainly to the Nursery class. When there are too many applicants to the Nursery class the school management may adapt a system of drawing lots to maintain maximum impartiality in granting admission. The management reserves its right to determine its own criterion for deciding the management and other quota of seats as the school is an unaided minority educational institution totally exempt from the RTE Act, 2009, and is under the constitutional purview of the minority rights. There shall be written test followed by an interview for admission to any class except for the Nursery Class. Selection will be strictly on merit, subject to the availability of seats in each class.The decision of the school authorities regarding registration and admission will be final. There is no specific syllabus for the Entrance test to any class. However, the criterion is to have the necessary knowledge based on which the students can continue his /her studies as per the ICSE/CBSE/PSEB curriculum and syllabus, in the class to which the admission is sought. The test will be conducted according to the standard of the school, based on the subjects taught in the previous classes.</p>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default AdmissionRequirement;
