import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LazyLoad from "react-lazyload";
const GeneralInformation = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>About</li>

          <li>General Information</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>General Information & School Prospectus</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>This school is established, owned and administered by the Diocese of Jalandhar, registered charitable society, as one among its several prestigious schools all over Punjab and certain parts of H.P. This is an unaided minorityeducational institution with all its privileges guaranteed by the Supreme Constitution of India, under the Articles 29, 30 (1). His Excellency, the Catholic Bishop of Jalandhar, appointed by the Holy Father (Pope) in Rome is the patron of this school and the president of the society.</p>
              <p> The main aim of the school is to provide a sound, value based and emancipating education, designed to train its pupils to live for God and humanity, by developing their multifarious talents through manifold activities, be it moral, spiritual, intellectual, social or professional. The school strives to maintain a student-friendly ambiance and encourages its wards to develop analytical, creative and independent thinking habit and assists the students to possess holistic perspectives, all round developments, positive thinking attitude and sound domain of knowledge with particular applications of it in daily living situations. </p>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <article className="border-box">
                <h5>Patron</h5>
                <LazyLoad>  <img
                  src="https://webapi.entab.info/api/image/SJSP/public/Images/bishop.jpg"
                  className="img-responsive img-fluid"
                  alt="St. Joseph's Convent School Phagwara"
                /></LazyLoad>
                <div className="clr"></div>
                <p>Rt. Rev. Dr. Franco Mulakkal</p>
              </article>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <article className="border-box">
                <h5>Director</h5>
                <LazyLoad>  <img
                  src="https://webapi.entab.info/api/image/SJSP/public/Images/director.jpg"
                  className="img-responsive img-fluid"
                  alt="St. Joseph's Convent School Phagwara"
                /></LazyLoad>
                <div className="clr"></div>
                <p>Rev. Fr. Jose Sebastian T</p>
              </article>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <article className="border-box">
                <h5>Principal</h5>
                <LazyLoad>  <img
                  src="https://webapi.entab.info/api/image/SJSP/public/Images/principal.jpg"
                  className="img-responsive img-fluid"
                  alt="St. Joseph's Convent School Phagwara"
                /></LazyLoad>
                <div className="clr"></div>
                <p>Sr. Lisbeth SABS</p>
              </article>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <article className="border-box">
                <h5>Vice Principal</h5>
               <LazyLoad> <img
                  src="https://webapi.entab.info/api/image/SJSP/public/Images/vice-principal.jpg"
                  className="img-responsive img-fluid"
                  alt="St. Joseph's Convent School Phagwara"
                /></LazyLoad>
                <div className="clr"></div>
                <p>Rev. Sr. Ann Maria</p>
              </article>
            </div>
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default GeneralInformation;
