import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
const ExaminatioPromotion = () => {

    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>Academics</li>
                    <li>Examinations & Promotions</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Examinations & Promotions</h1>
                        </div>
                    </div>
                    <div className="row uniform_blk">
                        <div className='col-md-12'>
                        <p>The School year has three terms : March to May, July to October, November to February.</p>
                        <ul className='list_view'>
                            <li>Examination will be held at the end of each term and no student will be allowed to take the examinations earlier or later. Those absent from an examination without a grave cause will be considered as having failed in the particular subject; in case of illness a doctor’s certificate should be produced.</li>
                            <li>Those who absent themselves in any subject shall be considered failed in that subject and shall not be awarded any position, and shall not be re-examined. In case of grave illness, a medical certificate to the effect shall be produced. </li>
                            <li>Regular periodical class-tests will be conducted in addition to the term examinations. Marks obtained in all the tests and term examinations will be taken into consideration for promotion. </li>
                            <li>A pupil shall be eligible to appear in the Final Examination only if he/she has a minimum of 75% of the total attendance. The same may be applicable also for the term examinations.</li>
                            <li>If anyone is  found indulging in any malpractice during an examination he/she is liable to be debarred from further examinations. </li>
                            <li>Criteria for a student to pass to the higher class after Final Examination shall be 45% of the aggregate marks obtained by the candidate in all the papers and examinations besides the internal assessment made by the school. </li>
                            <li>The Principal is at liberty to follow strictly the ICSE/CBSE/PSEB promotion scheme for the students, when deciding to promote a student to the higher class. Result declared at the end of the year will be final and will not be reconsidered, nor will the answer paper be shown. </li>
                            <li>A student who fails in two consecutive final examinations in the same class will have to leave the school.</li>
                            <li>As the school is totally exempt from the RTE Act as it is being an Unaided Minority Private school, promotion of all students to higher classes irrespective of the marks obtained for different subjects,(as recommended by the RTE Act,2009)  is not applicable to this school. Those who do not obtain the required marks will be detained in the same class; but not for more than two consecutive years in the same class.</li>
                            <li>Those who seek admission in this school will have to accept this scheme of promotion. The decision of the school in the matter of promotion is final and irrevocable. </li>
                            <li>Guardians are requested to examine the home work diary of the students to review each report from the school. It will be helpful in understanding as to why a child has not come up to the expectation. Such issues should be discussed with the subject teachers during the PTM.</li>
                            <li>Promotion to a higher class depends upon:
                                <ol>
                                    <li>Regularity in attendance. </li>
                                    <li>Regularity and quality of day-to-day participation in the class. </li>
                                    <li>Assessment by the teachersof different subjects.</li>
                                    <li>Written works and projects assigned by the subject’s teachers. </li>
                                    <li>Average Marks of the term examinations. </li>
                                </ol>
                            </li>
                        </ul>
                        </div> 
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ExaminatioPromotion

