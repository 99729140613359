import React from "react";
import { Link } from 'react-router-dom'; import Header from "../Component/Header";
import Footer from "../Component/Footer";
;

const ContactUs = () => {


    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>ContactUs</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>ContactUs</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="formbox">
                                <div class="formboxleft">
                                    <h5>Get In Touch</h5>
                                    <div class="addressdesc">
                                        <div class="addressimg"> <i class="bi bi-geo-alt-fill"></i></div>
                                        <Link to="https://maps.app.goo.gl/uBmgvEXUQaYwzHRk7" target="_blank">ST. &nbsp;JOSEPH'S CONVENT SCHOOL, PHAGWARA</Link>
                                    </div>
                                    <div class="addressdesc">
                                        <div class="addressimg"><i class="bi bi-envelope-fill"></i></div>
                                        <Link to="mailto:stjosephphg@gmail.com">stjosephphg@gmail.com</Link>
                                    </div>
                                    <div class="addressdesc">
                                        <div class="addressimg"><i class="bi bi-telephone-fill"></i></div>
                                        <Link to="tel:09257217537">9257217537  </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="col-lg-12">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3411.4822793509084!2d75.75185821466684!3d31.23506986830033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391af506c8dd606d%3A0x594a663bde8f609!2sSt.+Joseph's+Convent+School!5e0!3m2!1sen!2sin!4v1464928409762" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="contact_box"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ContactUs;
