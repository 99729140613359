import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
const SchoolHours = () => {

    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>Academics</li>
                    <li>School Hours</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>School Hours</h1>
                        </div>
                    </div>
                    <div className="row uniform_blk">
                        <div className='col-md-6'>
                            <h3 className='sub_title'>CLASS HOURS</h3>
                            <ul className="list_view">
                                <li><strong>SUMMER</strong> : 7.20 a.m. to 2.00 p.m.</li>
                                <li><strong>WINTER</strong> :8.20 a.m. to 2.30 p.m.</li>
                            </ul>
                        </div>
                        <div className='col-md-6'>
                            <h3 className='sub_title'>OFFICE HOURS</h3>
                            <ul className="list_view">
                                <li><strong>SUMMER </strong>: 8.00 a.m. to 1.00 p.m.</li>
                                <li><strong>WINTER </strong>: 9.00 a.m. to 2.00 p.m.</li>
                            </ul>
                        </div>
                        <div className='col-md-12'>
                            <h3 className='sub_title'>The Principal recevies visitors on class days</h3>
                        </div>
                        <div className='col-md-6'>
                            <ul className="list_view">
                                <li><strong>SUMMER</strong> :8.30 a.m. to 9.30 a.m. <br /> 12.00 noon to 1.00 p.m.</li>
                            </ul>
                        </div>
                        <div className='col-md-6'>
                            <ul className="list_view">
                                <li><strong>WINTER</strong> :9.30 a.m. to 10.30 a.m. <br /> 1.00 p.m. to 2.00 p.m.</li>
                            </ul>
                        </div>
                        <div class="col-lg-12">
                            <p>No School business will be transacted on Holidays. Correspondence concerning pupils should be addressed to the Principal.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default SchoolHours

