
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Home from'./Pages/Home'   
import ErpSoftware from'./Pages/ErpSoftware'   
import Events from'./Pages/Events'   
import Gallery from'./Pages/Gallery'   
import SubGallery from'./Pages/SubGallery'
import News from'./Pages/News'
import PrincipalMessage from'./Pages/PrincipalMessage'
import DirectorMessage from'./Pages/DirectorMessage' 
import Tc from'./Pages/Tc' 
import Toppers from'./Pages/Toppers' 
import ToppersXII from'./Pages/ToppersXII' 
import Pphelp from'./Pages/Pphelp' 
import About from'./Pages/About' 
import Motto from'./Pages/Motto' 
import Prayer from'./Pages/Prayer' 
import GeneralInformation from'./Pages/GeneralInformation' 
import HeavenlyPatrons from'./Pages/HeavenlyPatrons' 
import VisionAim from'./Pages/VisionAim' 
import AffiliationCourse from'./Pages/AffiliationCourse'
import Safety from'./Pages/Safety'
import AdmissionRequirement from'./Pages/AdmissionRequirement'
import Registration from'./Pages/Registration'
import Curricular from'./Pages/Curricular'
import SportsAndGames from'./Pages/SportsAndGames'
import Uniform from'./Pages/Uniform'
import SchoolHours from'./Pages/SchoolHours'
import RulesAndRegulations from'./Pages/RulesAndRegulations'
import ExaminatioPromotion from'./Pages/ExaminatioPromotion'
import Leadership from'./Pages/Leadership'
import ChemistryLab from'./Pages/ChemistryLab'
import PhysicsLab from'./Pages/PhysicsLab'
import BioLab from'./Pages/BioLab'
import Library from'./Pages/Library'
import ComputerLab from'./Pages/ComputerLab'
import LanguageLab from'./Pages/LanguageLab'
import ClassRoom from'./Pages/ClassRoom'
import ArtCraft from'./Pages/ArtCraft'
import KarateYoga from'./Pages/KarateYoga'
import MusicDance from'./Pages/MusicDance'
import ContactUs from'./Pages/ContactUs'
import VideoGallery from'./Pages/VideoGallery'
import Faculty from'./Pages/Faculty'
function App() { 
  return (
      <>
      <BrowserRouter>       
      <Routes>
       <Route path="/" element={<Home/>} /> 
       <Route path="/ErpSoftware" element={<ErpSoftware/>} /> 
       <Route path="/Events" element={<Events/>} /> 
       <Route path="/Gallery" element={<Gallery/>} /> 
       <Route path="/SubGallery" element={<SubGallery/>} /> 
       <Route path="/News" element={<News/>} /> 
       <Route path="/PrincipalMessage" element={<PrincipalMessage/>} /> 
       <Route path="/DirectorMessage" element={<DirectorMessage/>} />  
       <Route path="/Tc" element={<Tc/>} />  
       <Route path="/Toppers" element={<Toppers/>} />  
       <Route path="/ToppersXII" element={<ToppersXII/>} />  
       <Route path="/Pphelp" element={<Pphelp/>} />  
       <Route path="/About" element={<About/>} />  
       <Route path="/Motto" element={<Motto/>} />  
       <Route path="/Prayer" element={<Prayer/>} />  
       <Route path="/GeneralInformation" element={<GeneralInformation/>} />  
       <Route path="/HeavenlyPatrons" element={<HeavenlyPatrons/>} />  
       <Route path="/VisionAim" element={<VisionAim/>} />  
       <Route path="/AffiliationCourse" element={<AffiliationCourse/>} />  
       <Route path="/Safety" element={<Safety/>} />  
       <Route path="/AdmissionRequirement" element={<AdmissionRequirement/>} />  
       <Route path="/Registration" element={<Registration/>} />  
       <Route path="/Curricular" element={<Curricular/>} />  
       <Route path="/SportsAndGames" element={<SportsAndGames/>} />  
       <Route path="/Uniform" element={<Uniform/>} />
       <Route path="/SchoolHours" element={<SchoolHours/>} />
       <Route path="/RulesAndRegulations" element={<RulesAndRegulations/>} />
       <Route path="/ExaminatioPromotion" element={<ExaminatioPromotion/>} />
       <Route path="/Leadership" element={<Leadership/>} />
       <Route path="/ChemistryLab" element={<ChemistryLab/>} />
       <Route path="/PhysicsLab" element={<PhysicsLab/>} />
       <Route path="/BioLab" element={<BioLab/>} />
       <Route path="/Library" element={<Library/>} />
       <Route path="/ComputerLab" element={<ComputerLab/>} />
       <Route path="/LanguageLab" element={<LanguageLab/>} />
       <Route path="/ClassRoom" element={<ClassRoom/>} />
       <Route path="/ArtCraft" element={<ArtCraft/>} />
       <Route path="/KarateYoga" element={<KarateYoga/>} />
       <Route path="/MusicDance" element={<MusicDance/>} />
       <Route path="/ContactUs" element={<ContactUs/>} />
       <Route path="/VideoGallery" element={<VideoGallery/>} />
       <Route path="/Faculty" element={<Faculty/>} />
      </Routes>      
     </BrowserRouter>
      </>
  )
}

export default App;
