import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom' 
import {getPrincipalMessage} from'../Service/Api'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const PrincipalMessage = () => { 
    useEffect(() => {
        document.title = 'Principal';  
    }, []);  
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData1 = await getPrincipalMessage();
                const principalDate = principalData1.filter(item => item.category === "Principal")
                setData(principalDate);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
     <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                
                 <li>  Principal's Desk</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Principal's Desk</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    {data.length > 0 ? data.map((item) => (
                        <div className="clearfix">
                         <LazyLoad><img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="" className="col-md-4 float-md-end  left-img img-fluid"/></LazyLoad>
                         
                          <div dangerouslySetInnerHTML={{ __html: item.message }} /> 
                         <div className="mesdeskimg"> 
                         <h3> {item.name}</h3>
                         <h5> {item.category}</h5>
                      </div>
                         </div>
                     )) : emptyData.map((item) => (
                         <div className="clearfix"> 
                         <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/principal.jpg" className="col-md-4 float-md-end  left-img img-fluid"/></LazyLoad>
                        
                            <p>"Intelligence plus character : that is the goal of true education".(Martin Luther King Jr.)<br/>Education is not just the process of imparting knowledge alone but a lifelong learning process which enhances our understanding of moral and ethical values to guide one's life and make their future more bright and successful. We at St. Joseph play a pivotal role of providing education to the children by nurturing their in our pupils the valued spiritual growth in all the aspects of their life. We encourage all the students to contribute the give their very best in their attempt to realize their dreams. Students at St. Joseph are motivated to develop leadership skills and a thorough understanding of interdependence while holding independent views of their own. We strive for excellence in everything we do.<br />Warm Regards</p>
                            <div className="mesdeskimg"> <h3> Sr. Lisbeth SABS</h3> <h5>  Principal  </h5></div>
                            </div> 
                         ))}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
     </>
  )
}

export default PrincipalMessage

