import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
const RulesAndRegulations = () => {

    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>Academics</li>
                    <li>Rules and Regulations</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Rules and Regulations</h1>
                        </div>
                    </div>
                    <div className="row uniform_blk">
                        <div className='col-md-12'>
                            <ul className='list_view'>
                                <li>Prior to seeking admission to this school the parents are instructed to be familiar with the prospectus of the school. They must read and study the terms and conditions, rules and regulations and the code of conduct for the students and parents who will be associated with this school. Ignorance of the rules and regulations, terms and conditions will not in any way justify its violation. </li>
                                <li>All those who seek admission of their students in this school are bound to sign an affidavit after going through the prospectus of the school that they are willing to abide by the terms and conditions inscribed in it as a precondition for taking the admission.</li>
                                <li>The school is established, owned and administered by the Diocese of Jalandhar, registered charitable society, primarily to cater to the educational needs of its minority community members. Therefore the parents who take admission of their students in this school should respect the right of the Christian minority community members who play a major and primary role in the administration of the school.</li>
                                <li>Being an unaided minority educational institution its administration is entirely with the Christian minority registered society as per the provisions of the Supreme constitution of India. </li>
                                <li>The school enjoys its administrative freedom as provided by the Supreme Constitution of India in its Articles 29, 30 (1) which grants autonomy of administrative powers to minority educational institutions.</li>
                                <li>Admissions of students, fee structure, appointment, disciplinary action against the employees, dismissal of the employees, etc. are as per the administrative powers endowed on the minority institutions in conformity with the provisions of the Supreme Constitution of India, for minority educational institutions.</li>
                                <li>The school welcomes any suggestion and advice which is positive and constructive for the welfare of the children and better functioning of the school. But however the management reserves its right to accept or reject such suggestions. It is left to the prerogative of the management to implement such suggestions remaining within the administrative purview of the minority rights. The school is not bound to respond to the demands of any self-made associations or groups or even to the associations of parents and to their demands; but would be listening to the individual parents and their wards during the parents- teachers meetings.</li>
                                <li>Being an unaided minority private school the administration of the school is entirely under the provisions of the Articles 29 &30 (1) of the Supreme Constitution of India which grants autonomy to the minorities to administer its own educational institutions without undue interference from any unconstitutional external forces. </li>
                                <li>Those parents who cause any agitation and incite others to do so against the school or join with any anti-social element to disturb the peaceful atmosphere of the school forfeit the right to continue teaching their children in this school. Their children will be rusticated without giving any other reason. On special request their children may be allowed to complete the current academic session provided the school is given legal guarantee by submitting a written undertaking by the parents throughthe proper legal procedure, that the atmosphere of the school will not be disturbed for the sake of the majority of children studying in the school. The children of such parents will be issued the TC along with the final result of the academic year. The same will be applicable to the students who commit the similar offence.</li>
                                <li>The parents must sign a self- declaration at the time of taking admission of their children that they would send them only by such transportation which is run in accordance with the “safe-school vahan” policy of the Government to ensure the safety of their children. Any parent in connivance with private vehicle operators defy the “safe school vahan” policy of the government and send their children in vehicles which do not conform to the safety measures will be issued notice and subsequently will be asked to change the mode of transportation or withdraw their students from the school, for the sake of their safety.</li>
                                <li>Parents are strictly forbidden to send their children to the school by vehicles which do not run in compliance with the traffic regulations of Hon’ble Punjab and Haryana high court on children’s safety. The school issues permission to ferry the children only to those vehicle owners who have submitted their legal documents to the school in compliance with the Hon’ble high court order.</li>
                                <li>Since the school does not own vehicles for the transportation of the students who take admission in this school, the parents are instructed to make their own arrangements with private vehicle owners or transporters. However, they must make sure that such vehicles are being operated in compliance with the safety measures ordered by the Government. The school management gives approval to those vehicle owners who run the vehicles in conformity with the “safe school vahan” policy of the government.</li>
                                <li>The Transportation of the children to and from the School is a collective responsibility of all stake holders. The school-management cannot be held solely responsible for the safety of the children during their transportation, especially in case of private transporters with whom the parents have made agreement without any consultation with the school authority. In such cases the matter should be dealt with the concerned Government authorities, and the school management should not be unjustly dragged into controversies which would adversely affect the peaceful teaching atmosphere of the school.</li>
                                <li>The management has the right to take disciplinary action against students/parents who do not comply with school-rules and requirements. </li>
                                <li>The parents are requested to contact the school office in the month of March, before the beginning of the new academic year to know the fee structure of the coming year. Every year the school is constrained to enhance its fee structure, remaining within the limits permitted by the competent authority, as the school does not receive any financial aid from the Government.</li>
                                <li>Being an unaided minority educational institution, the school is not bound to constitute a Parents association with members from the majority community to be involved in the administrative matters of the school. However, the school has its own arrangements to know the minds of the parents through the PTM (parents teachers’ meeting) being conducted in regular intervals, and through the Local Managing Committee meetings in which the parents’ and teachers’ representatives are nominated.</li>
                                <li>All major decisions of administrative matters are taken by the Governing Body of the registered society which runs the school in consultation with the Local Managing Committee in which the representatives of the parents and teachers are members.</li>
                                <li>Parents and guardians are not permitted to walk into class-rooms or interview teachers without the permission of the Principal. </li>
                                <li>All letters and correspondence addressed to pupils are subject to the scrutiny of the Principal. Involvement of a student in any unhealthy friendship or indecent relationship with a person of the opposite or even the same sex, or any remarks, commends, jokes, exchanging of letters, sexually nuanced gestures etc. through verbal, textual, graphic or electronic media, of demeaning nature that goes against sexual modesty, will be treated as very serious offence and those involved  will be asked to discontinue studies in this school with immediate effect, after due enquiries through the internal complaint committee.</li>
                                <li>The school is not responsible for articles or money lost. It is not advisable to bring valuable articles (like expensive watches, fountain pens etc.) to school. Pupils below V Std. are advised not to wear  costly wrist watches in the school.</li>
                                <li>Parents are requested to refrain from disturbing the teaching staff of the school with any school matters outside the school hours, unless the matter is of very serious and urgent in nature. Phone calls or visit to the families of the teaching or non-teaching staff at odd hours which disturb their private family life is absolutely forbidden; and legal procedures will be initiated against those parents who violate the rules. All school matters relating to their students should be dealt with during the school hours only, preferably with prior appointment. </li>
                            </ul>
                            <h3 className='sub_title'>Disciplinary Action</h3>
                            <ul className='list_view'>
                                <li>Since the school management has strictly instructed its staff to avoid all kinds of corporal punishments, it has become the dire need to discipline the students only with the co-operation of the parents. Therefore it has been decided that on any serious in- disciplinary behaviour of a student “Letter of Information” will be sent to the concerned parents. The parents must then visit the school and discuss the matter with the Principal or the class-teacher and find a solution to discipline the child. If a child does not improve after three such interventions of sending the ‘Letter of information’ and giving corrections to improve, the student may be asked to discontinue his studies in this school, in consultation with the parents in the light of the information letter. Periodical Reports and the Report Book will keep the Parents/Guardians informed of the progress of their students. These reports are to be signed and returned in time as per the information supplied through the school diary. </li>
                                <li>Special classes, sports and games, cultural and literary activities may be organized on Saturdays as and when required. Participation will be deemed compulsory, in such cases.</li>
                                <li>Co-curricular activities and outdoor activities such as class picnics, camps, and study-tours shall be compulsory for all and expenses will be charged from the students. </li>
                                <li>Annual celebration and other such functions in which the parents are invited are expensive and needs special financial support of the parents. On such functions an additional fee will be charged from the parents other than the charges the participants would incur on the costume and special training.</li>
                                <li>The school will organize various functions and celebrations for the enrichment of the students on a regular basis. A small contribution from the parents would be charged for such functions and celebrations (preferably in the beginning of the year) which would not be included in the annual charges, or maintenance charges which the parents must pay annually other than the tuition fee.</li>
                                <li>In order to be in pace with the advancement in the process of teaching and learning, additional facilities would be added to the school curriculum in consultation with the parents. If the majority of the parents opt for such additional facilities, the rest of the parents should also opt for the same. An advance notice mentioning the proposed additional fee for the facility would be sent to all.  </li>
                                <li>Just because one child of yours is admitted in the school, it is not bound to give seats to all your other children/or of your relations. </li>
                                <li>Parents/Guardians are welcome to meet the principal from 10:00 a.m. (or on any specified time as per the notification issued) on working days, preferably with a prior appointment to save time and to avoid unnecessary inconveniences. </li>
                                <li>Please remember, education also means character formation and we need to discuss the matters with you. As per instructions from the council, value education and character formation need to be given prime importance during a child’s years of schooling. Therefore, henceforth the remarks registered in your child’s “Student’s Log Book” will also be entered in their Progress Report. We need Parent’s co-operation in molding the child’s behaviourthrough open dialogue between the teachers and the parents on days allotted for the P.T.M. (Parents-Teachers Meet).</li>
                                <li>The parents should be prompt to reach the school whenever they are invited either by the Principal or by any staff member. Continuous neglect from the part of the parents to visit the school will be serious violation of the terms and conditions for getting admission in this school. Therefore notice will be served to such parents and if they do not heed they will be asked to withdraw their child.</li>
                                <li>Parents are expected to visit the school in decent and modest attire. Visiting the school and the teaching staff or the Principal in short pants or casual dress which may be offensive, scandalous or considered to be immodest can be a serious offense. Such parents will be issued warning notice at the first instance. If continues to behave against the discipline of the school their intention will be considered as rebellious and the child will be issued T.C.</li>
                                <li>The school management will give due consideration to the decisions of the parents of a student only if both the parents are in agreement with the decision. This is to avoid all possible disputes and litigations later.  Therefore all the applications of any request concerning their children should be signed by both the parents. If any contingency arises that one of the parents is unable to sign an application, the necessary and convincing documentary evidence should accompany such application. The Principal of the school will be at liberty to verify the documents presented before him/her prior to granting the request.</li>
                                <li>It is not permitted for a parent to shout at a teacher either privately or in front of the student during the PTM. Such behaviour will be considered as serious indiscipline amounting to criminal offense and legal action will be initiated against such parent.</li>
                                <li>The parents should show due respect to the teachers as they belong to noble families and are engaged in a noble and respectable service to humanity. As human as they are any of their human frailty should not be made use to ridicule or belittle them before the students. Such acts will be considered as serious in-disciplinary behavior from the part of the parents and the concerned teacher will be at liberty to initiate legal action against the offender.</li>
                                <li>Any matter of dispute or clarification with regard to the progress in the study of a student should be discussed with the concerned teacher amicably by the parent; and if the matter is not settled, it should be brought to the knowledge of the Principal in written form. The parent should patiently wait for its written reply from the Principal or for a call from the Principal’s office to discuss the matter.</li>
                                <li>Any unruly behavior of a parent of a student to any staff member of the school (either teaching or nonteaching) will be considered as serious criminal offense and serious legal action will be initiated against such parent.</li>
                                <li>Lack of the knowledge of the law is no justification for its violation. Therefore the parents are requested to thoroughly read and understand or ask someone to explain these rules and regulations, terms and conditions before they take admission of their student in this school. </li>
                                <li>Moral uprightness and respectable social status is expected of every parent who takes admission of their children in this school. The children learn from their parents. The school does not expect a student to misguide another due to his improper upbringing at home by his parents.</li>
                                <li>Parents of those students studying in this school are expected to maintain respectable social status and should avoid getting involved in any criminal activities which will affect the student detrimentally in his life and progress in his studies.</li>
                                <li>The parents are not permitted to shout at the Principal or the staff or behave in a disrespectable manner with an attitude of intimidation which will demoralize the staff and they will not be able to carry on with their duty of guiding their students with tranquility and impartiality.</li>
                                <li>All the parents and visitors to the school are bound to fulfill the security regulations. Any defying of the security regulations will be construed as actions with evil or mala fide intention and the matter will be reported to the police for necessary action.</li>
                                <li>The security guards at the gate are employed to safeguard your children and the entire school staff. The parents should cooperate with the school by their compliance to the security norms. All disputes with the security guards are to be avoided as they are at your service and under the orders from the management for the benefit of your children.</li>
                                <li>The ID cards supplied from the school must be brought along if any of the parents or relatives wishes to meet a child during the school hours. To avoid any mishap to a student the school Principal and the staff will take maximum care not to divulge the identity of a child to any stranger, if he/she does not carry with him/her the identity card supplied from the school. </li>
                                <li>Being an unaided minority educational institution the school enjoys total exemption from the RTE Act, and therefore the students who fail to obtain the required marks for promotion as per the ICSEnorms would be detained in the same class and the students will be asked to repeat the year.</li>
                                <li>The tendency of the students to go for private tuition is strongly discouraged; instead they should be encouraged to participate in the remedial classes provided in the school itself. The school teachers shall not engage themselves for private tuition, except with the permission of the Principal. </li>
                                <li>Parent-Teacher Meeting (PTM) is to be attended by all parents/guardians of the students. Those students whose parents/guardians have not attended the PTM, without getting prior permission  from  the Principal, due to any unavoidable reasons, will not be allowed to attend the classes unless and until they bring their parents/guardians to the school.</li>
                                <li>Character formation of each student based on universal and eternal values is one of the key motives of the entire school administration. Therefore textbooks on moral science are compulsorily taught in the school. Besides extra instructions based on the booklet,“Hints for Personality development” is regularly imparted to the students.</li>
                                <li>In the progress report, along with the marks for different subjects taught in the school Grade is marked for the personality traits of each student. The parents should show keen interest in the personality development of their children by checking the progress marked in the progress report.</li>
                                <li>The school administration with the cooperation of the parents attempts to impart Strength of Character for every student that joints this institution.</li>
                                <li>The strength of character consists in developing the maximum of the following virtues:
                                    <ol>
                                        <li>Strength of Wisdom and Knowledge (Creativity, Inquisitiveness, Open-mindedness, Love of learning, Counseling perspective)</li>
                                        <li>Strength of Courage (Bravery, Persistence/perseverance, Integrity/authenticity/honesty, Vitality).</li>
                                        <li>Strength of Humanity (Love that prompts to share and care, Kindness/compassion/altruistic love, Social Intelligence.)</li>
                                        <li>Strength of Justice (Citizenship/social responsibility, Fairness/treating everyone without biased decisions, Leadership)</li>
                                        <li>Strengths of Temperance (Forgiveness and mercy, Humility/modesty, Prudence, Self-regulation/self-control.)</li>
                                        <li>Strengths of Transcendence (Appreciating beauty and excellence and being grateful to the Creator, Gratitude for the good things that happen in life, Hope/optimism/future expectation for the best, Humour/seeing the light side of things, Spirituality/trusting in religious faith. These strengths are explained in the booklet “Hints for personality development”.</li>
                                    </ol>
                                </li>
                                <li>If anyone, be it a teacher, parent, student or an employee, be found in an inebriated state in the school campus   stringent legal action will be initiated against him/her with immediate effect, leading even to the dismissal.</li>
                                <li>While sending letters or making remittance of fee or inquiries, please note the full name (as written in the admission form) of the child. His/her class and Roll Number/Admission No. are very important to enter because there are many students in a class/school with the same name.</li>
                                <li>Parents and guardians are requested to notify the school of any change in their address and phone numbers failing which the communications will only be sent on the present address in the school. The school will not entertain any complaint if one has not obtained the communication.</li>
                                <li>Children with any contagious illness should not be sent to the school until the doctor certifies that he/she is no more a carrier of the illness to another by contact. </li>
                                <li>The parents should not send children who are sick to take the examination during the exams. If the child is sick he/she must remain at home and send a leave application. If a student comes to school to take the examination, he/she will not be sent home before the scheduled time. But however in an emergency the parents will be informed for necessary intervention.</li>
                                <li>The parents should not intentionally withhold from revealing any serious health issue of a child to the Principal or the class teacher. Lack of transparency in such issues can be very much detrimental to the child; instead right information in due time to the school staff will benefit your child.</li>
                                <li>It is strictly forbidden for children to consume Junk food. The parents are requested to supply homemade healthy and hygienic food items as the Tiffin to be brought to the school.</li>
                                <li>Parents must regularly monitor their children; even check their school bag, so that they do not bring to school any items which are forbidden. If any such items are found they will be confiscated and strict disciplinary action will be taken against the offender.</li>
                                <li>The parents should forbid the children to bring to the school the following items and any other similar items which are in no way related to any school activities without obtaining  prior permission; items such as: Fireworks materials, Matches, Expensive toys or Weapons, Valuables, Computer games, Razor, Perfume, Knife, Yoyos, Darts, Sling shots and other potentially dangerous toys. </li>
                                <li>No student should possess any unauthorized sharp instrument or any lethal weapon which is in no way related to the study materials and neither should they bring any contraband items, intoxicants, drugs or anything objectionable to any inmate of the school. If any such material is found in possession of anyone he/she will be suspended from the school with immediate effect, and if the evil intention of the possessor is proved he/she will be debarred from attending this school.</li>
                                <li>The parents should not permit the children to imprint Tattoos on any part of their body and displaying it before others. Such acts are not considered to be conducts of persons with nobility and are considered to be showing disrespect to one’s body.</li>
                                <li>The school will not entertain any anonymous letters; instead every parent is permitted to lodge any of their complaints or suggestions through the suggestion box or through the provided provisions. </li>
                                <li>Any grievance should be conveyed in the written form to the Principal of the school in the first instance. If the grievance could not be settled by the Principal, it should be brought to the notice of the Director of the school. Only those issues which could not be settled locally should be brought to the Chairman of the Diocesan Board of Education at Jalandhar. The decision of the Chairman should be respected as full and final.</li>
                                <li>An appeal against the decision of the Chairman may be filed before the President of the registered society within thirty days of the receipt of the copy of the decision.  After the expiry of thirty days, if no appeal was made, the matter will be disposed of and the decision will be considered as full and final. </li>
                                <li>The decision taken by the office bearers of the Diocese of Jalandhar in the capacity of the office being held will not have any personal repercussion on him during his office or after relinquishing the office. The society as a juridical person will be responsible for all the decisions of its office bearers.</li>
                                <li>Any legal dispute between the management and the grieving party will be dealt only in the court within the jurisdiction of Jalandhar, as the school is established, owned and administered by the Diocese of Jalandhar, charitable society which has its headquarters in Jalandhar city.</li>
                                <li>The sole authority to order an investigation or grant permission to conduct an investigation on any matters of dispute, as exigencies demand, is vested with the President of the society and to whom he may delegate his authority as per his discretion.</li>
                            </ul>
                            <h3 className='sub_title'>Transportation</h3>
                            <ul className='list_view'>
                                <li>The transportation safety of the children is of at most concern for all of us. Therefore, we request the parents to consider seriously the following terms and conditions and take the necessary safety measures.
                                    <ol>
                                        <li>The school does not own vehicles for the transportation of the children taking admission in this school. Therefore, the parents must get the help of private vehicle operators for the transportation of their children.</li>
                                        <li>While choosing the private vehicles the parents are requested to choose only those vehicles which ply in conformity with the “Safe School VahanPolicy” of the Government for the safety of their children.</li>
                                        <li>Concerning the implementation of the “Safe school Vahan Policy” the school administration has been fully endorsing the ruling of the Hon’ble High Court of Punjab and Haryana and the instructions from the transportation department of the state by instructing the parents, students, the private vehicle owners, operators, and drivers to comply strictly with the policy norms. </li>
                                        <li>An office order has been issued by the Principal to all the vehicle operators and the parents, instructing them to comply with the “Safe School Vahan Policy”.</li>
                                        <li>The parents should sign a self-declaration at the time of taking admission of their children, stating that they would choose only those vehicles which comply with the “safe school vahan scheme” for the transportation of their children.</li>
                                        <li>A school transportation committee has been set up in the school to call the vehicle owners and drivers to instruct them periodically and to inspect their vehicles regularly to forewarn the defaulters. The committee will report to the competent Government authority the details of the vehicles which do not comply with traffic safety measures.  The parents must cooperate with the School transportation committee.</li>
                                        <li>The school has officially banned all the defaulting transporters from transporting the students to this school. Parents are requested not to engage such vehicles to transport your children.</li>
                                    </ol>
                                </li>
                            </ul>
                            <h3 className='sub_title'>B.CODE OF CONDUCT AND INFORMATION FOR STUDENTS AND STAFF</h3>
                            <ul className='list_view'>
                                <li>Students are expected to reach the school campus five to ten minutes before the first bell for morning assembly. Those who are late must obtain permission from the concerned person in charge, who makes entries at the gate in the diaries of late comers before allowing them to enter the school campus.</li>
                                <li>After making three late arrival entries in the diary, Rs. 20/- will be charged as fine for every subsequent late arrival from the late- comer.</li>
                                <li>Thestudents are not permitted to arrive too early to the school campus. If such need arises, such student should report his/her early arrival to the security guard and should spend the time under the scrutiny and protection of the security guard. He/she will be allowed to enter the school building or the class room only 10 minutes before the assembly bell.</li>
                                <li>The students who arrive too early before beginning the assembly are not allowed to loiter around. They should not be seen either alone or in company with other students in any isolated corners in the school campus. Instead, they should be in the playground in the visibility of the security guard and would be allowed to enter the school building or the class rooms only 10 minutes before the assembly bell.</li>
                                <li>Once a student has entered the school campus he/she will not be allowed to leave the campus without obtaining the gate pass from the competent authority.</li>
                                <li>The warning bell before the assembly is a signal for students to go to their respective class- rooms in perfect silence.</li>
                                <li>At the bell for beginning the assembly the students will be marching to the assembly ground from their respective class rooms in perfect silence and discipline. The class teachers will monitor their discipline.</li>
                                <li>All pupils are expected to be present for the assembly during which they pray together, and no one is to stay in the class room without obtaining prior permission on any very serious reason.</li>
                                <li>The attendance will be marked in the attendance register at the beginning of the two sessions: one at the arrival in the class after the assembly and the other after the noon-break.</li>
                                <li>The students are not allowed to roam about idly in the school campus or stay on in the classrooms after the last bell has been rung for leaving the school. </li>
                                <li>Any student whose transportation facility is delayed should inform the matter to the school office and wait at the reception other than loitering about outside the school gate or crowding around the street vendors or entering the adjacent shops to while away time.  </li>
                                <li>Students should be proactive in promoting a friendly school atmosphere and should workfor making the school a positive, supportive, safe and welcoming place.</li>
                                <li>Every student should bring daily the school diary to the school to facilitate the Principal/teachers to convey any information or request to the parents /guardians through the diary. If the parents/guardians are asked to meet the Principal/Teachers, they must do it without fail.</li>
                                <li>The students who are sick should not come to the school to take the examination during the exams days. If one is sick he/she must remain at home and send a leave application. If a student comes to school to take the examination, he/she will not be sent home before the scheduled time for the examination to get over. If any emergency arises the parents will be informed for their immediate intervention.</li>
                                <li>A leave application signed by the parents should be submitted to the office, if a student wants to take a day’s leave.  Any absence without prior leave application should be supported by a leave letter from the parents explaining the reason for absence on the day the absentee student returns to the school. In the absence of such leave application the student may be granted permission to attend the class on the same day but will be barred from entering the classroom on the next day.</li>
                                <li>Serious disciplinary action will be taken against a student who submits fabricated or fake leave letters or medical certificates to justify his absence. Forging the signature of anyone including those of one’s parents will be considered as serious criminal offense. If any such behaviours is detected the student will be suspended from the school with immediate.</li>
                                <li>A total of 75% school attendance is compulsory to be eligible to sit for the examinations; both for the term examinations and for the final examination in all classes.</li>
                                <li>Equal importance will be given for the academic progress and the character formation of every student.  Therefore it would be marked in the progress report the marks obtained for the academic subjects as well as the Grade obtained in the personality traits.</li>
                                <li>In each class a register will be maintained in which all the staff members are free to add points for every significant good character trait, mentioning the date and the description of the good deed as well as to enter minus point for every violation of the expected character trait or any delinquent behaviour. At the end of every term the plus and minus points will be tallied and the students who obtain above 90% points in good personality traits will be given appreciation award.</li>
                                <li>The Progress report/Proficiency profile record will be prepared after taking into consideration the remarks mentioned in the personality traits register maintained in each class.</li>
                                <li>In the progress report, along with the marks obtained for different subjects taught in the school, Grade is marked for the personality traits of each student. The parents should show keen interest in the personality development of their students by checking the progress marked in the progress report.</li>
                                <li>It is compulsory for the students to read the specified number of books from the Library in each term. Marks will be added for the books read and marks will be reduced for noncompliance of the rule. A summary of each book read, consisting of answers to the questions: Namely, (1) What was the book all about? (2) What did you gain from reading the book? (3) Which chapter or what idea, what concept, what incident, or what expression etc. of the book struck you/impressed you most? should be written and submitted to the respective language teacher. At the end of the term the language teacher will pick up any of those summaries and may ask questions to make sure that the person still remembers what he/she had read. Accordingly marks will be awarded as per the instruction of the Principal.</li>
                                <li>All letters and correspondence addressed to pupils are subject to the scrutiny of the Principal. Involvement of a student in any unhealthy friendship or indecent relationship with a person of the opposite or same gender, or any remarks, commends, jokes, exchanging of letters, sexually nuanced gestures etc. through verbal, textual, graphic or electronic media, of demeaning nature that goes against sexual modesty, will be treated as very serious offence and such students will be asked to discontinue studies in this school with immediate effect.</li>
                                <li>The students are forbidden to bring to the school the following items and any other similar items which are in no way related to any school activities without obtaining prior permission: Fireworks materials, Matches, Expensive toys or Weapons, Valuables, Computer games, Razor, Perfume, Knife, Yoyos, Darts, Sling shots and other potentially dangerous toys. If any such items are found with any student strict disciplinary action will be taken.</li>
                                <li>It is not permitted for children to imprint Tattoos on any part of their body and to display them to others. Such acts are not considered to be conducts of persons with nobility and are considered to be showing disrespect to one’s body. The same rule is applicable to girls who decorate their bodies for attracting others which may be considered as immodest and socially unacceptable to children of noble families. Those who defy the rule will be issued the “letter of information” to be shown to the parents and the matter should be settled in consultation with the parents.</li>
                                <li>Any criminal activity through the social media amounting to cybercrime among the students will be considered very serious offense for which a student may be suspended from the school with immediate effect; and the parents will be contacted to take the student home.  Subsequently he/she may be dismissed from the school as per the seriousness of the case.</li>
                                <li>No student should go on ruminating in his/her mind thoughts and plans of personal vendetta against any staff or student. If ever it is detected that someone is carrying on with revengeful thoughts and hatred against another the matter will be treated as very serious offense as amounting to attempt to cause injury to another. Such a student will not be allowed to continue to study in the school unless and until the parents of both the parties submit written undertaking that they alone will be responsible for any future eventualities of hurt or harm to anyone of the parties. </li>
                                <li>All the students should make it a point to settle any matter of dispute as soon as it arises with the help of the staff or the Principal. </li>
                                <li>Attempt to express or actually expressing any physical intimacy which is socially unacceptable either in private or in public between two students, either of the same gender or between the opposite genders is a serious offense. Strict disciplinary action will be taken against the offenders.</li>
                                <li>Use of abusive language in written or oral form by any student is a serious offense and such students will not be allowed to continue in the school if he/she does not give up such habit.</li>
                                <li>Bullying in any form either physically or mentally or through social media among the students is a serious offense and if anyone is found to be engaged in such activities he/she will be suspended from the school with immediate effect. As per the seriousness of the matter disciplinary action will be initiated against the delinquent student.</li>
                                <li>The students are to immediately report to the Internal Complaint Committee member of the school any type ofoffensive behavior with sexual intent from the part of any person in the school campus or outside. In this way one can help the school authority to book the offender. One must not gossip around such delicate issues to anyone and everyone, instead, such issues should be immediately brought to the knowledge of one of the Internal Complaint Committee members only. At the same time it is to be borne in mind that if an innocent person is falsely implicated, the complainant shall be prosecuted with severe penalties. </li>
                                <li>Watching any pornographic videos through any type of social media or through the internet is absolutely forbidden to children. If ever such behavior is reported to the school authority strict and stringent disciplinary action will be taken against the student.</li>
                                <li>The students are not permitted to develop any clandestine, secret, friendship with anyone either inside or outside the school campus.  All their friends should be known to their parents and even to the teachers. If ever it is found that such friendship is going on severe disciplinary action will be taken against the students and even they could be expelled from the school.</li>
                                <li>The school is not responsible for articles or money lost. It is not advisable to bring valuable articles (like expensive watches, fountain pens etc.) to school. Pupils below V Std. are advised not to wear costly wrist watches in the school.</li>
                                <li>In the case of girl students, they are not allowed to wear costly ornaments when they come to the school. If ever they bring such costly adornments and if they are lost the school will not entertain any such complaints. </li>
                                <li>No student shall be allowed to go out or to be taken out of the school compound without obtaining the ‘out pass’ from the Principal. The out pass shall not be issued unless the Principal is convinced of the genuineness of the need and the credentials of the person who has come with the request to take the student from the school for whatever purpose.</li>
                                <li>A student will not be allowed to meet any visitor unless he/she carries with him/her the identity card issued from the school.</li>
                                <li>Personal Cleanliness and grooming is expected of every student. The school uniform must be clean, well pressed and complete without any type of decorations. Students coming to the school with untidy dress, hair etc. will be fined and if one does not oblige to follow the school instruction a “letter of information “will be sent to the parents. The student will be allowed to continue studies in the school only after the settlement of the issue in consultation with the parents.</li>
                                <li>The class-teachers are duty bound to check diligently the uniform of the students regularly. They should regularly insist on the hair-style of the boys and girls that they follow the regulation of the school. </li>
                                <li>During the recess for the lunch break pupils are expected to leave the classrooms only after having eaten their lunch. The teachers on duty are expected to take care that the students peacefully consume their Tiffin.</li>
                                <li>The students should be highly motivated to bring their Tiffin those food items which are prepared at their home kitchen by their mothers or their dear ones. It is highly injurious for children to consume junk food. All kind of junk food items are strictly banned.</li>
                                <li>It is highly encouraged for children of all classes to have a fruit break after the second period in the morning.  The students should be motivated to bring any fruit item and consume it in the class during the fruit recess.</li>
                                <li>It is not healthy and hygienic for children to have their lunch (Tiffin) squatting on the floor or in the ground; and therefore, the meals should be taken sitting on benches in the class room itself. </li>
                                <li>The Principal has the right to confiscate books, newspapers, periodicals, mobile phones and any other items which may be considered objectionable. No student should possess any unauthorized sharp instrument or any lethal weapon which is in no way related to the study materials and neither should they bring any contraband items, intoxicants, drugs or anything objectionable to any inmate of the school. If any such material is found in possession of anyone he/she will be suspended from the school with immediate effect, and if the evil intention of the possessor is proved he/she will be debarred from attending this school. </li>
                                <li>The academic session of the school will be from March to Feb. The medium of instruction and expression is English within the school campus. Punjabi, the mother tongue is taught form L.K.G. and Hindi, the national language from U.K.G. </li>
                                <li>All students are advised to practice speaking English within the school Campus. But however, no child should be punished or fined for speaking in their mother tongue. Speaking in English should be encouraged not by punishment but by motivation.</li>
                                <li>The school management will arrange special coaching classes for Hindi on special request for those students who have taken Hindi as an optional subject in 9th and 10th Std. However, an extra fee will be charged for the same.</li>
                                <li>Money should not be lent or borrowed or articles exchanged between the students, without due permission from the class-teacher. </li>
                                <li>No collection of money for any purpose whatsoever is to be started and no meeting, demonstration, party or picnic may be held without previous sanction of the Principal. </li>
                                <li>Giving presents or gifts to the members of the staff or any type of demonstration of gratitude towards them also requires previous sanction from the Principal.</li>
                                <li>Any pupil desiring to be exempted from PT, games or any other activities in the school must produce a doctor’s certificate to the effect that he/she is physically unfit to take part therein. </li>
                                <li>Children have no permission to attend parties or to go to the cinema or to go to friend’s house on their way to the school or on the way back home from the school.</li>
                                <li>All Children especially the girls should avoid at any cost the company of any stranger on their way to the school or on the way back home. Never should they accept any edible items from strangers and neither should they accept if a stranger offers a free lift in their vehicle to reach you to the school or to home.</li>
                                <li>All pupils should have their books in a canvas or leather bag. Aluminum suitcase will not be allowed.</li>
                                <li>Care must be taken to protect all school- property. No pupil should scratch or carve on the desks or chairs or damage any school furniture. Writing or drawing pictures on the walls, breaking or damaging school property or things belonging to others are all strictly forbidden. Damage done to any property even by accident should be reported at once to the class teacher or to the Principal. Any damage done must be made good by the one who does it. Anyone who sees that something is damaged should report the matter even if he/ she does not know who has done it. No Pupil should bring razor blade or any sharp instruments unwarranted to school. </li>
                                <li>Students are not permitted to wear such wrist watches which functions as minicomputers or mobile phones which can be used to do clandestine activities in the class, in the examinations and in the school campus.</li>
                                <li>Running, playing, shouting and whistling inside the school building is never allowed.</li>
                                <li>The name, Admission number, standard and section of the pupil should be clearly marked on all his/her belongings. </li>
                                <li>The students should behave in a refined and polite manner wherever they go; they should always remember that the standard of the school is judged by the behavior of its students. They should always greet their teachers, elders and friends in a pleasant and audible tone of voice wherever they meet them in school or outside. They should be gentle and polite to their companions and others.</li>
                                <li>Pupils shall be cheerful in their obedience to their parents and teachers giving them always the respect and affection that is their due and accepting their guidance and correction with gratitude. </li>
                                <li>Cycles must be kept locked at the cycle stand only. Students should not ride around the school compound on bicycles. While entering or leaving the school gate, one must walk instead of riding.</li>
                                <li>Students who do not possess a valid driving license are not allowed to come to the school by driving Motor vehicles. </li>
                                <li>Students who come to school under the care of servants or any guide or guard, should never leave before they arrive. In case of delay they should report to the school office. </li>
                                <li>Those who go home alone should not loiter about on the way. They should be prompt in returning home. Students are warned not to buy anything especially eatables from street vendors. </li>
                                <li>Students will not be called to answer phone calls during class hours, unless it is very urgent.</li>
                                <li>The Principal has the right to suspend or request the withdrawal of any pupilin the interest of the school provided that he/she judges that such a step is necessary. It is not necessary that the Principal should convince the concerned student or his/her parents the reason why the student is asked to be withdrawn from the school. The matter is left to the discretion of the Principal alone.</li>
                                <li>The school has the right to teach any subject that is required and approved by the Board of Education both as compulsory and optional. </li>
                                <li>No student is permitted to bring cell phone to the school complex. If any student is found with a mobile phone, the phone will be confiscated and will be returned only at the end of the academic year on condition that such violation of discipline will not be repeated in future. Parents and teachers are required to switch off their mobile phones in the school complex.</li>
                                <li>The students are not permitted to invite any of their friends to the school campus from outside who are not presently studying in the school for whatever purposes without taking prior permission from the Principal. </li>
                                <li>After the school hours no student will be allowed to roam around or loiter outside the school gate. Those students whose transportation vehicles or their parents happened to be late to arrive to pick them up, must spend their waiting time inside the school campus. A negative remark will be entered against the character traits of those students who are found loitering outside.</li>
                                <li>Duplicate copies of Transfer Certificate are ordinarily not issued. In special case of urgency a bona fide certificate may be issued after verification, on payment of Rs.100/-.stating that the particular student was studying in this school during a particular tenure. </li>
                                <li>A student who is caught using unfair means in the examination will be awarded zero in the concerned paper and it could lead to his dismissal from the school.</li>
                                <li>A student who threatens the parents or the school authority with dire consequence of himself/herself committing suicide or to harm oneself or others on any disciplinary action on him/her will be immediately dismissed from the school. Information of any such oral or written threat will disqualify him/her to continue the studies in this school except through a written undertaking by the parents that they will not hold the school in any way responsible for such drastic step by their son/daughter. And a copy of the written undertaking (affidavit) will be given to the Police station.</li>
                                <li>No student should hold any personal vendetta against any staff member or any student in the school. If a student has any dispute against anyone it should be brought to the knowledge of the Principal or any staff member in whom the student has confidence. If any personal feud or vendetta is found to be going on, all involved persons will be suspended from the school with immediate effect.</li>
                                <li>The school will be free to introduce additional facilities in consultation with the parents as per the need and advancement in the method of teaching. The parents should be willing to subscribe to such additional facilities for the benefit of their children. If the majority of the parents give their consent to such additional facility the other parents should cooperate with the majority.</li>
                            </ul>
                            <h3 className='sub_title'>Important Information</h3>
                            <ul className='list_view'>
                            <li>Duplicate copy of “School Diary” will be issued only with the consentof the Principal, after verification of the request and on a payment of Rs. 100/-.  Children must remember to bring their school diary daily to school and should take care of it that it is not lost. </li>
                            <li>Each student must possess two identity cards issued by the school. One should be worn around neck by the student whenever he is in the school complex and the other should be with the parents to show at the gate whenever the parents want to meet their students. </li>
                            <li>No physical or verbal violence is permitted in the school. If anyone is foundhaving recourse to such behaviour, he/she will be suspended with immediate effect and an enquiry will be conducted which may lead to one’s dismissal if proved guilty. </li>
                            <li>The school has constituted an internal complaints committee (ICC) to look into any sexual or physical harassment of the students or employees in the school. Any matters pertaining to such issues should be immediately reported to the said committee. The committee will then deal with the matter as per the legal norms.</li>
                            <li>The same committee will look into all matters pertaining to the POCSO Act 2012 and sexual harassment of women at work place (prevention, prohibition  and redressal) Act, 2013 and will follow the necessary legal procedure. </li>
                            <li>Allotting to students different  Sections/Houses and shuffling them into different classes,  as and when required, is fully at the discretion of the school authorities and no request for a particular Section/House or changing of Section/House will be entertained, unless it is found very essential.</li>
                            <li>The tendency of the students to go for private tuition is strongly discouraged; instead they should be encouraged to participate in the remedial classes provided in the school itself. The school teachers shall not engage themselves for private tuition, except with the permission of the Principal. </li>
                            <li>Parent-Teacher Meeting (PTM) is to be attended by all parents/guardians of the students. Those students whose parents/guardians have not attended the PTM, without getting prior permission  from  the Principal, due to any unavoidable reasons, will not be allowed to attend the classes unless and until they bring their parents/guardians to the school. </li>
                            <li>If anyone, be it a teacher, parent, student or an employee, be found in an inebriated state in the school campus   stringent legal action will be initiated against him/her with immediate effect, leading even to the dismissal.</li>
                            <li>While sending letters or making remittance of fee or inquiries, please note the full name (as written in the admission form) of the child. His/her class and Roll Number/Admission No. is very important to enter because there are many students in a class/school with the same name.</li>
                            <li>The students are requested to notify to the school office any change in their address and phone numbers through a letter of application from their parents in which there should be the signature of both the parents. </li>
                            <li>The school will not entertain any anonymous letters; instead every parent is permitted to lodge any of their complaints or suggestions through the suggestion box or through the provisions provided.</li>
                            <li>Character formation of each student based on universal and eternal values is one of the key motives of the entire school administration. Therefore textbooks on moral science arecompulsorily taught in the school. Besides extra instructions based on the booklet “Hints for Personality development” is regularly imparted to the students.</li>
                            </ul>
                            <h3 className='list_view'>Use of Library</h3>
                            <p>The school has a rich library with a variety of books considering the multifarious interest of the students. The students are encouraged to read as many books as possible to quench their thirst for knowledge. The following guidelines are to be followed for the orderly use of the library.</p>
                            <ul className='list_view'>
                            <li>Strict discipline and silence is to be observed in the library and reading room. </li>
                            <li>Library books will be issued to the senior classes which can be returned only during Library period. Anyone failing to return the book on the due date shall incur a fine of Rs.10/- per day. </li>
                            <li>Marking, underlining, cutting, erasing or damaging pages of the books are strictly prohibited. Any damage or loss of library books shall be restituted by replacement of the book or by paying its cost with an additional fine by the borrower according to the discretion of the Librarian/Principal. </li>
                            <li>Periodicals/Magazines are to be read in the Library/Reading Room only and the same are to be placed in their specific places after use. </li>
                            <li>If necessary the Librarian/Principal may recall a book at any time. </li>
                            <li>All pupils from Fifth standard onwards must read a minimum of 12 English books, 6 Hindi books and 6 Punjabi books in a year. These could be equally divided in three terms. A one page summary of each book read must be shown to the class teacher or else 2 marks for every book not thus read will be deducted from English, Hindi and Punjabi paper in the Final examination. The number of books to be read during the academic year could be divided for each of the three terms in the year.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default RulesAndRegulations


