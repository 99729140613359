import React from 'react';
import { Link } from 'react-router-dom'  
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const Motto = () => { 
    
  return (
     <>
     <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>About</li>                
                 <li> Motto</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Motto</h1>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-6'>
                    <p>The motto of our school is <br /><span><b> 'SCIRE AMARE ET SERVIRE' <br/>'COME TO LEARN AND GO TO SERVE'</b></span></p>
                    </div>
                    <div className='col-md-6'>
                    <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/motto.png" className='img-fluid'></img></LazyLoad>
                    </div>
                </div>
            </div>
        </div>
      
        <Footer />
     </>
  )
}

export default Motto

