import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
const Registration = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link>{" "}</li>
          <li>Admission</li>
          <li>Registration</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Registration</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
               <ul className="list_view">
                    <li>Maximum age at the time of Registration for the std Nursery should be of 3 years (+) – by 31 March 2023.</li>
                    <li>Maximum age at the time of Registration for the std L.K.G should be of 4 years (+) – by 31 March 2023.</li>
                    <li>Maximum age at the time of Registration for the std U.K.G should be of 5 years (+) – by 31 March 2023.</li>
                    <li>This Registration does not guarantee admission of the child. Admission will be decided on the basis of the written test and oral test for L.K.G. and U.K.G.</li>
                    <li><span>School does not have its own vehicles for the transportation. So, it is parents’ duty to negotiate with the private vehicle owners for the arrangement of the transportation of their wards.</span></li>
                    <li>Date of submission of duly filled registration form and Acknowledgment receipt will be intimated later.</li>
                    <li>Date of written test and oral test for Std L.K.G. and U.K.G. will be intimated later.</li>
                    <li>Date of school visit for Std Nursery will be intimated later.</li>
                    <li>Documents required at time of online Registration:-<br />
                        <ul>
                            <li>a. Passport size photograph of the ward.</li>
                            <li>b. Aadhar Card number of the ward.</li>
                        </ul>
                    </li>
                    <li>Documents required at the time of school visit for Std Nursery and entrance test for Std L.K.G. and U.K.G.
                        <ul>
                            <li>a. Print out of duly filled Registration form.</li>
                            <li>b. Print out of Acknowledgment receipt.</li>
                            <li>c. Original ID proof of parents.</li>
                            <li>d. 2 passport size photographs of the ward.</li>
                        </ul>                        
                    </li>
                    <li>Documents required at the time of Admission: -
                        <ul>
                            <li>a. 4 passport size photographs of the ward.</li>
                            <li>b. Passport size photograph of mother, father and guardian.</li>
                            <li>c. Attested copy of Aadhar Card of ward.</li>
                            <li>d. Attested copy of Date of Birth Certificate of ward.</li>
                        </ul>
                    </li>
                </ul>
                <p><span>Without Date of Birth Certificate and Aadhar Card of the ward, admission will not be accepted.</span></p>
                <iframe src="https://webapi.entab.info/api/image/SJSP/public/pdf/Admission.pdf" width="100%" height="800px"></iframe>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default Registration;
