import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const KarateYoga = () => {
    return (
        <>
            <Header />
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li>Infrastructure</li>
                    <li>Karate & Yoga</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>Karate & Yoga</h1>
                        </div>
                    </div>
                    <div className="row uniform_blk">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/Yoga.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/infra_karate1.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/infra_karate2.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/infra_karate3.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/infra_karate4.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/infra_karate4.jpg" alt="St. Joseph's Convent School Phagwara" class="img-responsive border-box"/></LazyLoad></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default KarateYoga

