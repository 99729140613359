import React from 'react'
const HomeSlider = () => {
  return (
    <>
      <div className="slider-sec">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/SJSP/public/Images/slide1.png" className="d-block w-100" alt="St. Joseph's Convent School, Phagwara" /> </div>
            <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/SJSP/public/Images/slide1.png" className="d-block w-100" alt="St. Joseph's Convent School, Phagwara" /> </div> 
          </div> 
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
        </div>
        <div className="slider-info">
          <div id="carouselExampleCaptions1" className="carousel slide" data-bs-ride="carousel1">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="1" aria-label="Slide 2"></button>
            </div>
          </div>
        </div>
        <div className="overBox">
          <h2>Will Durant</h2>
          <p>Knowledge is the eye of desire and can became the polot of the soul</p>
          <button className='button'>EXPLORE <i class="bi bi-chevron-double-right"></i></button>
        </div>
      </div>
    </>
  )
}

export default HomeSlider
