import { useEffect, useState } from "react";
import { Link } from 'react-router-dom'  
import { getFaculty } from "../Service/Api";
import Header from '../Component/Header';
import Footer from '../Component/Footer';
const Faculty = () => { 
    const [data, setData] = useState([]); 

    useEffect(() => {
        const fetchData = async () => {
          try {
            const fetchedData = await getFaculty();
            console.log(fetchedData); // Inspect the response
            const sortedData = fetchedData.sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
              setData(sortedData);
          } catch (error) {
            console.error("Error fetching faculty data:", error);
          }
        };
        fetchData();
      }, []);
  return (
     <>
     <Header />
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>About</li>                
                 <li> Faculty</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Faculty</h1>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-12'>
         <div className='table-responsive'>
            <table>
                <thead>
                    <tr>
                    <th>S. No.</th>
<th>Employee Name</th>
<th>Designation</th>
<th>Category</th>
                    </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.designation}</td>
                            <td>{item.category}</td>
                          </tr>
                      )) }
                </tbody>
            </table>
         </div>
                    </div>
                     
                </div>
            </div>
        </div>
      
        <Footer />
     </>
  )
}

export default Faculty

