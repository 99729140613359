import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import LazyLoad from "react-lazyload";
const Safety = () => {
  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li>About</li>

          <li>Fire Safety & Building Safety
          </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Fire Safety & Building Safety</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
            <LazyLoad><img class="img-responsive img-fluid border-box" src="https://webapi.entab.info/api/image/SJSP/public/Images/fire_safety.jpg" alt="St. Joseph's Convent School Phagwara" /></LazyLoad>
            </div> 
            <div className="col-md-6">
            <LazyLoad><img class="img-responsive img-fluid border-box" src="https://webapi.entab.info/api/image/SJSP/public/Images/building-safety.jpg" alt="St. Joseph's Convent School Phagwara" /></LazyLoad>
            </div> 
          </div>
        </div>
      </div> 
      <Footer />
    </>
  );
};

export default Safety;
